import React, { useContext } from "react";
import Context from "../../context";

const Carousel = (props) => {
  const { state, dispatch } = useContext(Context);
  let carouselStyles = {
    height: `${props.square}px`,
    left: state.leftPosString,
  };
  let cItems = state.items.map((item, i) => {
    let cItemsStyle = {
      height: `${props.square}px`,
      width: `${props.square}px`,
      backgroundImage: `url(${item.thumbnail})`,
    };
    const updateActiveSlide = () => {
      if (state.videoPlaying) {
        dispatch({
          type: "TOGGLE_PLAYER",
          payload: !state.videoPlaying,
        });
      }
      dispatch({ type: "UPDATE_ACTIVESLIDE", payload: item });
    };
    return (
      <div
        className="slide_item"
        style={cItemsStyle}
        key={i}
        onClick={updateActiveSlide}
      >
        <p>{item.title}</p>
      </div>
    );
  });
  return (
    <div className="items-wrap" style={carouselStyles}>
      {cItems}
    </div>
  );
};
export default Carousel;
