import React, { useContext } from "react";
import Context from "../../context";

const MobileHeader = () => {
  const { state, dispatch } = useContext(Context);
  const navClick = () => {
    dispatch({
      type: "TOGGLE_MOBILE_MENU",
      payload: !state.mobileMenu,
    });
  };
  let mMenu = (
    <div className={`mobile-menu ${state.mobileMenu ? "show" : ""}`}>
      <span className="close" onClick={navClick}>
        X
      </span>
      <div className="mm-img mm-container">
        {/* <img src="../../images/logo-nwe-color.png" alt="" /> */}
        <img src="../../images/NWE_abrev2.png" alt="" />
      </div>
      <div className="mm-list mm-container bebas">
        <a href="#who" onClick={navClick} className="mm-link">
          Who we are
        </a>
        <a href="#contact" onClick={navClick} className="mm-link">
          contact us
        </a>
      </div>
    </div>
  );

  return (
    <div className="row nwe-header mobile">
      {mMenu}
      <div className="mobile-header-img">
        <img src="../../images/NWE_abrev2.png" alt="" />
      </div>
      <nav className="navbar navbar-expand-lg navbar-light">
        <button
          // className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarToggleExternalContent"
          aria-controls="navbarToggleExternalContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={navClick}
        >
          <span className="navbar-toggler-icon"></span>
        </button>
      </nav>
    </div>
  );
};

export default MobileHeader;
