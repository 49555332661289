import React, { useEffect } from "react";

import "./loader.styles.scss";

const Loader = (props) => {
  useEffect(() => {
    window.scrollTo(1000, 0);
  }, []);
  return (
    <div className="loader-component-wrap">
      <div className="loader-wrap">
        <svg
          id="elfs7po675lq1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 377 695"
          shapeRendering="geometricPrecision"
          textRendering="geometricPrecision"
        >
          <defs>
            <linearGradient
              id="logo-gradient"
              x1="0%"
              y1="0%"
              x2="100%"
              y2="0%"
            >
              <stop offset="0%" stopColor="#f7941e">
                <animate
                  attributeName="stop-color"
                  values="#f7941e; #787878; #f7941e;"
                  dur="3s"
                  repeatCount="indefinite"
                ></animate>
              </stop>
            </linearGradient>
          </defs>

          <g id="elfs7po675lq2">
            <g id="elfs7po675lq3">
              <path
                id="elfs7po675lq4"
                d="M19.806200,0.033229C25.206200,-0.396771,35.996200,3.433230,43.386200,6.503230L78.666200,20.903200C111.506000,34.903200,154.306000,45.073200,160.306000,76.573200C162.706000,89.173200,162.466000,97.823200,162.466000,113.073000L162.466000,177.143000C162.466000,186.313000,162.516000,197.633000,160.266000,204.983000C158.016000,212.333000,153.786000,218.983000,147.806000,222.573000C134.856000,230.403000,101.316000,222.163000,85.806200,219.573000C46.806200,213.073000,14.306200,212.073000,4.306160,186.073000C0.436155,176.033000,0.186155,156.843000,0.186155,143.033000L0.186155,41.783200C0.186155,30.033200,-1.143840,17.583200,3.306160,10.033200C5.056920,7.168930,7.468850,4.766090,10.339700,3.026150C13.210600,1.286220,16.456800,0.259894,19.806200,0.033229Z"
                transform="matrix(1 0 0 1 -1.09517201000000 -0.00000005000001)"
                fill="url('#logo-gradient')"
                stroke="none"
                strokeWidth="1"
              />
              <path
                id="elfs7po675lq5"
                d="M202.636000,74.573200C211.306000,74.243200,222.076000,79.073200,233.466000,84.023200C244.306000,88.743200,248.726000,90.503200,258.806000,94.823200C274.556000,101.573000,287.706000,121.573000,287.706000,138.023000C287.706000,149.303000,287.636000,160.583000,287.636000,171.863000L287.636000,214.243000C287.636000,229.133000,282.906000,238.493000,270.806000,243.743000C260.656000,248.143000,250.636000,245.743000,235.306000,243.403000C219.976000,241.063000,214.636000,239.903000,206.976000,236.903000C193.976000,231.833000,182.586000,214.453000,182.586000,202.743000C182.506000,187.743000,182.426000,173.943000,182.346000,158.903000L182.346000,111.623000C182.346000,102.393000,181.866000,93.153200,184.636000,86.743200C186.185000,83.224800,188.696000,80.215600,191.881000,78.062400C195.066000,75.909200,198.794000,74.699700,202.636000,74.573200Z"
                fill="url('#logo-gradient')"
                stroke="none"
                strokeWidth="1"
              />
              <path
                id="elfs7po675lq6"
                d="M321.476000,123.403000C350.996000,123.403000,375.996000,151.033000,375.996000,175.033000C376.076000,185.433000,376.156000,200.883000,376.236000,211.283000C376.236000,240.283000,377.836000,253.203000,354.636000,259.423000C349.786000,260.723000,342.876000,260.123000,338.316000,259.183000C319.206000,255.273000,308.016000,247.053000,303.776000,228.533000C301.346000,217.903000,301.776000,206.033000,301.776000,187.043000C301.776000,148.533000,300.376000,142.103000,306.776000,131.533000C310.466000,125.533000,315.196000,123.643000,321.476000,123.403000Z"
                fill="url('#logo-gradient')"
                stroke="none"
                strokeWidth="1"
              />
              <path
                id="elfs7po675lq7"
                d="M28.066200,243.863000C37.621300,243.636000,47.175400,244.363000,56.586200,246.033000C69.706200,247.633000,83.686200,249.933000,96.806200,251.533000C123.916000,256.273000,142.416000,253.313000,154.986000,273.103000C163.066000,285.833000,161.466000,304.833000,161.466000,325.663000L161.466000,381.583000C161.466000,391.583000,162.466000,403.503000,160.026000,411.343000C157,420.863000,150.711000,429.008000,142.266000,434.343000C131.536000,441.043000,116.416000,440.803000,101.266000,443.493000C88.866200,445.013000,80.266200,445.803000,62.766200,447.993000C30.766200,451.993000,10.526200,456.753000,2.766160,431.493000C-0.603844,420.493000,0.146155,401.803000,0.146155,388.493000L0.146155,305.033000C0.146155,288.713000,-0.763845,268.513000,5.466160,258.233000C8.355130,253.761000,12.218200,250.001000,16.766200,247.233000C20.231000,245.273000,24.093800,244.121000,28.066200,243.863000Z"
                transform="matrix(1 0 0 1 -0.29141095000000 0)"
                fill="url('#logo-gradient')"
                stroke="none"
                strokeWidth="1"
              />
              <path
                id="elfs7po675lq8"
                d="M211.626000,265.943000C219.797000,266.144000,227.951000,266.772000,236.056000,267.823000C242.806000,268.823000,248.056000,269.353000,255.786000,270.983000C269.306000,273.823000,282.126000,285.503000,286.506000,299.983000C289.426000,309.683000,288.206000,329.733000,288.206000,341.783000C288.206000,352.343000,287.946000,363.783000,287.946000,374.383000C287.946000,380.463000,288.246000,388.123000,287.056000,393.283000C283.626000,408.153000,273.696000,418.173000,260.106000,422.863000C253.206000,425.243000,244.306000,426.283000,234.806000,427.783000C209.476000,431.783000,192.436000,429.893000,184.306000,408.783000C181.096000,400.453000,182.306000,387.093000,182.306000,376.063000L182.306000,314.183000C182.306000,301.443000,181.726000,288.953000,186.386000,281.063000C190.166000,274.663000,196.516000,269.573000,202.516000,267.573000C205.448000,266.552000,208.522000,266.002000,211.626000,265.943000Z"
                fill="url('#logo-gradient')"
                stroke="none"
                strokeWidth="1"
              />
              <path
                id="elfs7po675lq9"
                d="M332.056000,281.323000C347.306000,281.323000,371.446000,288.053000,375.556000,311.823000C376.436000,316.883000,376.266000,338.083000,376.266000,349.483000C376.266000,372.573000,377.056000,380.323000,374.266000,387.323000C367.726000,403.963000,356.766000,412.323000,338.266000,414.323000C328.266000,415.403000,323.506000,413.593000,319.266000,411.573000C300.626000,402.723000,302.316000,380.823000,302.316000,350.573000C302.316000,316.323000,301.436000,309.483000,305.516000,299.073000C308.656000,291.073000,313.766000,287.573000,317.516000,285.073000C321.266000,282.573000,327.296000,281.323000,332.056000,281.323000Z"
                fill="url('#logo-gradient')"
                stroke="none"
                strokeWidth="1"
              />
              <path
                id="elfs7po675lq10"
                d="M344.056000,434.323000C361.306000,430.823000,376.056000,446.823000,376.056000,459.383000L376.296000,488.903000L376.296000,521.033000C376.296000,534.293000,367.846000,556.033000,342.726000,566.903000C334.546000,570.433000,327.266000,573.653000,317.836000,571.293000C302.836000,567.543000,302.136000,552.783000,302.136000,532.073000L302.136000,499.223000C302.136000,477.573000,301.736000,472.023000,304.836000,463.073000C309.236000,450.353000,319.466000,440.343000,329.586000,437.573000C333.996000,436.373000,337.626000,435.623000,344.056000,434.323000Z"
                fill="url('#logo-gradient')"
                stroke="none"
                strokeWidth="1"
              />
              <path
                id="elfs7po675lq11"
                d="M255.306000,449.033000C259.386000,448.437000,263.547000,448.731000,267.502000,449.895000C271.458000,451.060000,275.114000,453.067000,278.221000,455.778000C281.327000,458.490000,283.809000,461.843000,285.496000,465.605000C287.184000,469.367000,288.037000,473.450000,287.996000,477.573000L287.996000,557.823000C287.996000,580.073000,273.356000,595.573000,242.356000,607.823000C223.146000,615.413000,207.356000,623.823000,196.856000,619.823000C181.806000,614.093000,182.636000,597.403000,182.636000,576.573000L182.636000,517.033000C182.636000,507.353000,181.876000,494.493000,184.106000,486.373000C185.720000,480.033000,188.688000,474.117000,192.806000,469.033000C203.016000,456.243000,219.486000,455.283000,238.806000,451.283000C243.766000,450.523000,248.256000,450.033000,255.306000,449.033000Z"
                transform="matrix(1 0 0 1 -0.29141095000000 0)"
                fill="url('#logo-gradient')"
                stroke="none"
                strokeWidth="1"
              />
              <path
                id="elfs7po675lq12"
                d="M129.306000,469.703000C147.826000,469.493000,158.086000,478.703000,161.226000,493.703000C162.616000,500.413000,162.446000,508.503000,162.446000,515.993000L162.446000,608.323000C162.446000,615.713000,160.086000,621.923000,157.306000,627.323000C153.676000,634.373000,148.936000,640.573000,143.556000,644.823000C135.046000,651.533000,120.466000,657.093000,106.556000,662.903000C87.506200,670.833000,76.856200,675.573000,60.236200,682.323000C45.456200,688.323000,25.856200,699.323000,12.606200,692.573000C-1.763850,685.273000,0.426155,669.323000,0.426155,637.943000L0.426155,559.463000C0.426155,524.323000,-1.713840,507.173000,18.306200,492.573000C28.206200,485.353000,41.916200,484.573000,56.586200,481.703000L108.186000,473.063000L129.306000,469.703000Z"
                transform="matrix(1 0 0 1 -0.29141095000000 0)"
                fill="url('#logo-gradient')"
                stroke="none"
                strokeWidth="1"
              />
            </g>
          </g>
        </svg>
      </div>
    </div>
  );
};
export default Loader;
