import React, { useContext } from "react";
import Context from "../../context";

import ReactPlayer from "react-player";
import Pagination from "./Paginitation.component";
import "./hero.styles.scss";

const ActiveSlide = () => {
  const { state, dispatch } = useContext(Context);
  const slideHeroStyle = {
    backgroundImage: `url(${state.activeSlide.heroImage})`,
    backgroundColor: "#000",
  };
  let ShowVideo = () => {
    dispatch({
      type: "TOGGLE_PLAYER",
      payload: !state.videoPlaying,
    });
  };
  let heroPlayer = (
    <ReactPlayer
      className="hero-payer show"
      width="100%"
      height="100%"
      controls={true}
      playing={true}
      url={`https://vimeo.com/video/${state.activeSlide.vimeoId}`}
    />
  );

  return (
    <div className="slide_hero" style={slideHeroStyle}>
      {!state.isMobile && <Pagination />}
      <div className={`slide-cta ${state.videoPlaying ? "hide" : ""}`}>
        <h2 className="hero-title">
          {state.activeSlide.title} - {state.activeSlide.company}
        </h2>
        <button className="play-btn" onClick={ShowVideo}>
          <span className="play"> </span>
        </button>
      </div>
      {state.videoPlaying && heroPlayer}
    </div>
  );
};
export default ActiveSlide;
