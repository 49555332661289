import React, { useEffect, useContext } from "react";
import { graphql } from "react-apollo";
import { heroData } from "../../graphql/queries";

import "./hero.styles.scss";
import Measure from "react-measure";
import Context from "../../context";

import ActiveSlide from "./ActiveSlide.component";
import Carousel from "./Carousel.component";
import Arrow from "./Arrow.component";

const Hero = ({ data: { loading, error, heroItems } }) => {
  const { state, dispatch } = useContext(Context);

  const handleRezie = (dimzn) => {
    if (state.dimensions !== dimzn) {
      dispatch({
        type: "UPDATE_DIMENSIONS",
        payload: dimzn,
      });
    }
  };
  const { width } = state.dimensions;
  let slideSquare = Math.round(width / 4);
  let slideSquareString = {
    height: `${slideSquare}px`,
  };
  useEffect(() => {
    let hItems = heroItems
    if (hItems) {
      hItems = hItems.edges;
      hItems = hItems.map((item) => {
        let clean = {
          title: item.node.title,
          heroImage: item.node.heroItems.heroBackground.mediaItemUrl,
          thumbnail: item.node.heroItems.thumbnail.mediaItemUrl,
          vimeoId: item.node.heroItems.vimeoId,
          company: item.node.heroItems.company,
        };
        return clean;
      });
      dispatch({ type: "ADD_CONTENT", payload: hItems });
      dispatch({ type: "UPDATE_ACTIVESLIDE", payload: hItems[0] });
      dispatch({ type: "IS_LOADED", payload: !state.isLoaded });
    }
  }, [heroItems, dispatch, state.isLoaded]);

  if (error) return <h2>Error loading carousel</h2>;
  if (state) {
    return (
      <Measure
        bounds
        onResize={(contentRect) => {
          handleRezie(contentRect.bounds);
        }}
      >
        {({ measureRef }) => (
          <div className="slide_wrap row" ref={measureRef}>
            <ActiveSlide />
            <div className="itemsContainer" style={slideSquareString}>
              <Arrow direction="back" spacing={slideSquare} />
              <Carousel square={slideSquare} spacing={slideSquare} />
              <Arrow direction="next" spacing={slideSquare} />
            </div>
          </div>
        )}
      </Measure>
    );
  }
};
export default graphql(heroData)(Hero);
