import React, { useContext, useRef } from "react";
import Context from "../../context";
import Carousel from 'react-bootstrap/Carousel';

const Finishing = ({ data, images }) => {
  const { state, dispatch } = useContext(Context);
  const closeFinishing = () => {
    dispatch( { type: "TOGGLE_FINISHING", payload: !state.finishingActive } );
    document.getElementById( "finishingOpen" ).focus();
  };

  // Build Toggle Button Layout
  let toggleBtn = (
    <button
      type="button"
      className="btn btn-outline-light bebas abt-link"
      onClick={closeFinishing}
      aria-label="Close Finishing modal"
    >
      close
    </button>
  );

  /*
    Build Carousel Slides.
    As long as the value isn't null for the URL then build a slide element.
  */
  const carouselItems = Object.keys( images ).map( (image) => {
      if ( images[image] !== null ) {
        return <Carousel.Item key={image}>
          <img
            className="d-block w-100"
            src={images[image]}
            alt={"Slide " + image.replace("fimage", "")}
          />
        </Carousel.Item>
      }
      return null;
    }
  )

  const finishingWrap = useRef(null);

  return (
    <div
      id="finishingSection"
      className={`nwe-finishing-content ${state.finishingActive ? "show" : ""}`}
      ref={finishingWrap}
    >
      <div className="row">
        <div className={`col fpillar fmain`}>
          <img className="finishingImg" src={data.sourceUrl} alt={data.title} />
          <h3>{data.title}</h3>
          <p>{data.copy}</p>
          {!state.isMobile && toggleBtn}
        </div>
        <div className="col-lg-8 fpillar fcopy">
          <Carousel fade aria-hidden="true">
            {carouselItems}
          </Carousel>
          <p>{data.fcopy1}</p>
          <p>{data.fcopy2}</p>
        </div>
      </div>
      <div className="row">
        <div className="col">
          {state.isMobile && toggleBtn}
        </div>
      </div>
    </div>
  );
};
export default Finishing;
