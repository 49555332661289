import React, { useContext } from "react";
import Measure from "react-measure";
import Context from "../../context";
import "./Header.styles.scss";
import MobileHeader from "./mobileHeader";
import DesktopHeader from "./desktopHeader";

const Header = ({ isMobile }) => {
  const { state, dispatch } = useContext(Context);
  const handleResize = (measurement) => {
    let width = measurement.width;
    if (width <= 994 && !state.isMobile) {
      dispatch({ type: "IS_MOBILE", payload: true });
    } else if (width >= 1024 && state.isMobile) {
      dispatch({ type: "IS_MOBILE", payload: false });
    }
  };
  let headerContent;
  if (state.isMobile) {
    headerContent = <MobileHeader />;
  } else {
    headerContent = <DesktopHeader />;
  }

  return (
    <Measure
      bounds
      onResize={(contentRect) => {
        handleResize(contentRect.bounds);
      }}
    >
      {({ measureRef }) => (
        <div className="row nwe-header-wrap" ref={measureRef}>
          {headerContent}
        </div>
      )}
    </Measure>
  );
};
export default Header;
