import React, { useContext } from "react";
import { graphql } from "react-apollo";
import { aboutData } from "../../graphql/queries";
import Context from "../../context";
import Finishing from "./Finishing.component";

import "./AboutUs.styles.scss";

const AboutUs = ({ data: { loading, error, aboutUss, pillars } }) => {
  const { state, dispatch } = useContext(Context);
  const handleFinishing = () => {
    dispatch( { type: "TOGGLE_FINISHING", payload: !state.finishingActive } );
    document.getElementById( "finishingSection" ).focus();
  };
  if (error) return <h2>something went wrong</h2>;
  if (aboutUss && pillars) {
    //   NWE about us setup
    let aboutTitleNwe = aboutUss.edges[0].node.aboutUS.title;
    let aboutCopyNwe = aboutUss.nodes[0].content.replace("<p>", "");
    aboutCopyNwe = aboutCopyNwe.replace("</p>", "");
    let finishingContent = {
      title: null,
      backgC: null,
      sourceUrl: null,
      copy: null,
      fcopy1: null,
      fcopy2: null,
    };
    let finishingImages = {
      fimage1: null,
      fimage2: null,
      fimage3: null,
      fimage4: null,
      fimage5: null,
      fimage6: null,
      fimage7: null,
    };

    // Pillars setup - Randomly assigns layout
    let pillarsArr = pillars.edges.sort(() => Math.random() - 0.5);
    pillarsArr = pillarsArr.map((pillar, i) => {
      let currentPillar = pillar.node;
      let backgC = {
        backgroundColor: currentPillar.pillarMeta.backgroundColor,
      };

      let copy = currentPillar.content.replace("<p>", "").replace("</p>", "");
      let link;
      // If the pillar doesn't have an external link, render as Finishing pillar with Finishing data.
      if ( currentPillar.pillarMeta.link === null ) {
        // Fill finishingContent Object
        finishingContent.copy = copy;
        finishingContent.fcopy1 = currentPillar.pillarMeta.extendedInfo;
        finishingContent.fcopy2 = currentPillar.pillarMeta.extendedInfoCopy;
        finishingContent.backgC = backgC;
        finishingContent.sourceUrl = currentPillar.pillarMeta.logo.sourceUrl;
        finishingContent.title = currentPillar.title;
        // Fill finishingImages Object
        finishingImages.fimage1 = currentPillar.pillarMeta.extendedInfoImage.sourceUrl;
        finishingImages.fimage2 = currentPillar.pillarMeta.extendedInfoImage2.sourceUrl;
        finishingImages.fimage3 = currentPillar.pillarMeta.extendedInfoImage3.sourceUrl;
        finishingImages.fimage4 = currentPillar.pillarMeta.extendedInfoImage4.sourceUrl;
        finishingImages.fimage5 = currentPillar.pillarMeta.extendedInfoImage5.sourceUrl;
        finishingImages.fimage6 = currentPillar.pillarMeta.extendedInfoImage6.sourceUrl;
        finishingImages.fimage7 = currentPillar.pillarMeta.extendedInfoImage7.sourceUrl;
        link = (
          <button
            id="finishingOpen"
            className="btn btn-outline-light bebas abt-link"
            aria-label="Learn more about NWE Finishing" // The only pillar without a link should be NWE Finishing, if that changes modify this aria-label to something dynamic
            onClick={handleFinishing}
          >
            {currentPillar.pillarMeta.linkLabel}
          </button>
        );
      } else {
        link = (
          <a
            href={currentPillar.pillarMeta.link}
            className="btn btn-outline-light bebas abt-link"
            rel="noopener noreferrer"
            target={"_blank"}
          >
            {currentPillar.pillarMeta.linkLabel}
          </a>
        );
      }
      return (
        <div
          className={`col-12 col-md-6 pillar ${currentPillar.title.replace("Project X|AV", "projectXAV").replace("NWE Finishing", "nweFinishing")}`}
          style={backgC}
          key={i}
        >
          <img
            className="pillar__img"
            src={currentPillar.pillarMeta.logo.sourceUrl}
            alt={currentPillar.title}
          />
          <h3>{currentPillar.title}</h3>
          <p className="pillar__copy">{copy}</p>
          {link}
        </div>
      );
    });
    return (
      <div className="row nwe-about-us" id="who">
        <div className="col intro">
          <h2>{aboutTitleNwe}</h2>
          <p>{aboutCopyNwe}</p>
        </div>
        <div
          className="row pillars-wrap"
        >
          <div className="pillar-chevron">
            <svg width="100%" height="100%" viewBox="0 0 500 35">
              <polygon points="0,0 500,0 250,35" className="pillar-chevron"></polygon>
            </svg>
          </div>
          <div className="row pw">{pillarsArr}</div>
          <Finishing data={finishingContent} images={finishingImages} />
        </div>
      </div>
    );
  } else {
    return <p>Loading</p>;
  }
};
export default graphql(aboutData)(AboutUs);
