import React from "react";
import { graphql } from "react-apollo";
import { socialData } from "../../graphql/queries";
import "./Social.styles.scss";

const Social = ({ data: { loading, error, socialPlatforms } }) => {
  if (error) {
    return <h3>ERROR LOADING!!</h3>;
  } else if (socialPlatforms) {
    let sm = socialPlatforms.nodes[0].socialMedia;
    return (
      <div className="row row nwe-footer">
        <div className="col social-wrap">
          <div className="row social-icons-wrap">
            <div className="col social-icons">
              <a
                href={sm.facebookLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={sm.facebookIcon.sourceUrl} alt="" />
              </a>
            </div>
            <div className="col social-icons">
              <a
                href={sm.instagramLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={sm.instagramIcon.sourceUrl} alt="" />
              </a>
            </div>
            <div className="col social-icons">
              <a
                href={sm.twitterLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={sm.twitterIcon.sourceUrl} alt="" />
              </a>
            </div>
            <div className="col social-icons">
              <a
                href={sm.linkedinLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={sm.linkedinIcon.sourceUrl} alt="" />
              </a>
            </div>
          </div>
        </div>
        <div className="col-md-3 social-title">
          <h2>follow us</h2>
        </div>
      </div>
    );
  } else {
    return <h3>Loading...</h3>;
  }
};
export default graphql(socialData)(Social);
