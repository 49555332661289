import React from "react";
import { graphql } from "react-apollo";
import { contactData } from "../../graphql/queries";
import "./ContactUs.styles.scss";

const ContactUs = ({ data: { loading, error, contactUs } }) => {
  let cd;
  if (error) return <h3>Something Went Wrong!</h3>;
  if (contactUs) {
    cd = contactUs.nodes[0].contactUs;
    return (
      <div className="row nwe-contact-us" id="contact">
        <div className="col-md-3 contact-us_title">
          <h2>contact us</h2>
        </div>
        <div className="col info-wrap">
          <div className="row contact-us_info">
            <div className="col info-col info-address">
              <img src={cd.addressIcon.sourceUrl} alt="" />
              <h3>Address</h3>
              <a
                href="https://goo.gl/maps/JEbaB4xAtgvm8Mps7"
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
                <p>
                  2660 W Olive Ave. <br /> Burbank, CA 91505
                </p>
              </a>
            </div>
            <div className="col info-col info-phone">
              <img src={cd.phoneIcon.sourceUrl} alt="" />
              <h3>Phone</h3>
              <p>(818) 295-5000</p>
            </div>
            <div className="col info-col info-email">
              <img src={cd.generalInquiriesIcon.sourceUrl} alt="" />
              <h3>General Inquiries</h3>
              <p>
                <a href={`mailto:${cd.generalInquiries}`}>
                  {cd.generalInquiries}
                </a>
              </p>
            </div>
            <div className="col info-col info-careers">
              <img src={cd.careersIcon.sourceUrl} alt="" />
              <h3>Careers</h3>
              <p>
                <a href={`mailto:${cd.careers}`}>{cd.careers}</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <div className="row nwe-contact-us">LOADING...</div>;
  }
};
export default graphql(contactData)(ContactUs);
