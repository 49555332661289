import gql from "graphql-tag";

export const heroData = gql`
  {
    heroItems {
      edges {
        node {
          title
          heroItems {
            company
            heroBackground {
              mediaItemUrl
            }
            thumbnail {
              mediaItemUrl
            }
            vimeoId
          }
        }
      }
    }
    siteHeadlines {
      edges {
        node {
          title
        }
      }
    }
  }
`;

export const aboutData = gql`
  {
    aboutUss {
      edges {
        node {
          aboutUS {
            title
          }
        }
      }
      nodes {
        content
      }
    }
    pillars {
      edges {
        node {
          title
          content
          pillarMeta {
            logo {
              sourceUrl
            }
            link
            backgroundColor
            extendedInfo
            extendedInfoCopy
            linkLabel
            extendedInfoImage {
              sourceUrl
            }
            extendedInfoImage2 {
              sourceUrl
            }
            extendedInfoImage3 {
              sourceUrl
            }
            extendedInfoImage4 {
              sourceUrl
            }
            extendedInfoImage5 {
              sourceUrl
            }
            extendedInfoImage6 {
              sourceUrl
            }
            extendedInfoImage7 {
              sourceUrl
            }
          }
        }
      }
    }
  }
`;

export const contactData = gql`
  {
    contactUs {
      nodes {
        contactUs {
          address
          addressIcon {
            sourceUrl
          }
          careers
          careersIcon {
            sourceUrl
          }
          generalInquiries
          generalInquiriesIcon {
            sourceUrl
          }
          phone
          phoneIcon {
            sourceUrl
          }
        }
      }
    }
  }
`;

export const socialData = gql`
  {
    socialPlatforms {
      nodes {
        content
        socialMedia {
          facebookIcon {
            sourceUrl
          }
          facebookLink
          instagramIcon {
            sourceUrl
          }
          instagramLink
          linkedinIcon {
            sourceUrl
          }
          linkedinLink
          twitterIcon {
            sourceUrl
          }
          twitterLink
        }
      }
    }
  }
`;
