export default function reducer(state, action) {
  switch (action.type) {
    case "IS_MOBILE":
      return {
        ...state,
        isMobile: action.payload,
      };
    case "IS_LOADED":
      return {
        ...state,
        siteLoaded: action.payload,
      };
    // Hero
    case "ADD_CONTENT":
      return {
        ...state,
        items: [...action.payload],
      };
    case "UPDATE_ACTIVESLIDE":
      return {
        ...state,
        activeSlide: action.payload,
      };
    case "TOGGLE_PLAYER":
      return {
        ...state,
        videoPlaying: action.payload,
      };
    case "UPDATE_DIMENSIONS":
      return {
        ...state,
        dimensions: action.payload,
      };
    case "RESET_SCROLL":
      return {
        ...state,
        leftPos: action.payload.lp,
        leftPosString: action.payload.lps,
      };
    case "TOGGLE_MOBILE_MENU":
      return {
        ...state,
        mobileMenu: action.payload,
      };
    case "TOGGLE_FINISHING":
      return {
        ...state,
        finishingActive: action.payload,
      };
    case "RELEASE_LOADER":
      return {
        ...state,
        loaderRelease: action.payload,
      };
    default:
      return state;
  }
}
