import React, { useContext } from "react";
import Context from "../../context";

const Pagination = () => {
  const { state, dispatch } = useContext(Context);

  const pagiDots = state.items.map((item, key) => {
    const updateActiveSlide = () => {
      if (state.videoPlaying) {
        dispatch({
          type: "TOGGLE_PLAYER",
          payload: !state.videoPlaying,
        });
      }
      dispatch({ type: "UPDATE_ACTIVESLIDE", payload: item });
    };
    let active = false;
    if (item.title === state.activeSlide.title) {
      active = true;
    }
    return (
      <span
        className={`pagi-dots ${active ? "active" : " "}`}
        key={key}
        onClick={updateActiveSlide}
      >
        {" "}
      </span>
    );
  });
  return <div className="pagination">{pagiDots}</div>;
};
export default Pagination;
