import React, { useContext } from "react";
import Context from "../../context";

const Arrow = ({ direction, spacing }) => {
  const { state, dispatch } = useContext(Context);
  let { leftPos } = state;

  const handleMove = () => {
    let baseN;
    if (direction === "next") {
      baseN = leftPos + 1;
      posMath(baseN, moveForward);
    } else {
      baseN = leftPos - 1;
      posMath(baseN, moveBack);
    }
  };

  const posMath = (base, cb) => {
    let basePx = -Math.abs(base * spacing) + "px";
    cb(base, basePx);
  };
  const moveBack = (pos, pixel) => {
    if (state.leftPos === 0) return;
    dispatch({ type: "RESET_SCROLL", payload: { lp: pos, lps: pixel } });
  };

  const moveForward = (pos, pixel) => {
    if (state.leftPos === state.items.length - 4) {
      resetMove();
      return;
    }
    dispatch({ type: "RESET_SCROLL", payload: { lp: pos, lps: pixel } });
  };

  const resetMove = () => {
    dispatch({ type: "RESET_SCROLL", payload: { lp: 0, lps: "0px" } });
  };

  let arrowImage = direction === "next" ? "arrow-right" : "arrow-left";
  return (
    <div className={arrowImage} onClick={handleMove}>
      <img src={`./images/${arrowImage}.png`} alt="" />
    </div>
  );
};
export default Arrow;
