import { createContext } from "react";

const Context = createContext({
  isMobile: false,
  mobileMenu: false,
  siteLoaded: false,
  finishingActive: false,
  loaderRelease: false,
  // Hero State
  items: [],
  dimensions: {
    width: -1,
    height: -1,
  },
  leftPos: 0,
  leftPosString: "0px",
  currentSlide: 0,
  activeSlide: {
    title: "",
    heroImage: "",
    thumbnai: "",
    vimeoId: "",
    company: "",
  },
  videoPlaying: false,
  slidesCount: 0,
});

export default Context;
